jQuery.extend({

    mTimeline: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder      : _this,
            lastItemH   : null,
            holderLine  : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.holderLine         = _private.holder.find(".m-timeline__line");
                _private.lastItem           = _private.holder.find(".m-timeline__item").last();
                
            },
            setProperties:function() {
                
                var holderLineHeight = _private.holder.height(); 
                var lastItemHeight = _private.lastItem.height();
                var newHeight = parseInt(holderLineHeight - lastItemHeight); 

                //Set new height on timeline line
                _private.holderLine.css("height" , "" + newHeight + "px");

            },
            update:function() {
                _private.setProperties();
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.setProperties();
            
            // WINDOW RESIZE
            function doResize() {

                _private.update();
                
            }
            var resizeTimer;
            $(window).resize(function() {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(doResize, 500);
            });

        }
        
             
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mTimeline = new $.mTimeline($(".m-timeline"));
    });

    
});