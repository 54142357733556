jQuery.extend({

    mActsSlider: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            holderItems : null,
            buttonLeft  : null,
            buttonRight : null,
            itemCount   : null,
            visibleItemCount   : null,
            holderWidth : null,
            itemWidth   : null,
            lastVisible : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.holderItems        = _private.holder.find(".m-acts__items");
                _private.itemsWrapper       = _private.holderItems.find(".m-acts__items__wrapper");
                _private.buttonPrev         = _private.holder.find(".m-acts-slider__btn-prev");
                _private.buttonNext         = _private.holder.find(".m-acts-slider__btn-next");

                _private.itemCount          = _private.itemsWrapper.find(".m-acts__item").length;

                _private.holderWidth        = _private.holderItems.outerWidth();
                _private.itemWidth          = _private.holderItems.find(".m-acts__item:first-child").outerWidth() + 30;

                _private.visibleItemCount   = parseInt(_private.holderWidth / _private.itemWidth);
                _private.lastVisible        = _private.visibleItemCount;

            },
            setProperties:function() {
                
                var totalWidth = parseInt( _private.itemCount * _private.itemWidth);

                if (_private.holderWidth < totalWidth) {
                    _private.showBtnNext();
                }

                _private.itemsWrapper.css("width" , "" + totalWidth + "px");
            },
            showBtnPrev:function() {
                _private.buttonPrev.addClass("visible");
            },
            hideBtnPrev:function() {
                _private.buttonPrev.removeClass("visible");
            },
            showBtnNext:function() {
                _private.buttonNext.addClass("visible");
            },
            hideBtnNext:function() {
                _private.buttonNext.removeClass("visible");
            },
            clickListener:function() {
                    _private.buttonPrev.unbind().bind("click", _private.leftClicked);
                    _private.buttonNext.unbind().bind("click", _private.rightClicked);
            },
            leftClicked:function() {
                _private.lastVisible--;

                if (_private.lastVisible == _private.visibleItemCount){
                    _private.hideBtnPrev();

                     _private.itemsWrapper.animate({
                        left: "+=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    });
                }
                else if(_private.lastVisible > _private.visibleItemCount) {
                     _private.itemsWrapper.animate({
                        left: "+=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    });
                }

                if (_private.lastVisible < _private.itemCount) {
                    _private.showBtnNext();
                }
            },
            rightClicked:function() {

                 _private.lastVisible++;

                if (_private.lastVisible < _private.itemCount) {

                    _private.itemsWrapper.animate({
                        left: "-=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    }); 

                }
                else if (_private.lastVisible == _private.itemCount) {
                    _private.hideBtnNext();

                    _private.itemsWrapper.animate({
                        left: "-=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    }); 
                }

                if (_private.lastVisible > _private.visibleItemCount){
                    _private.showBtnPrev();
                }

            },
            update:function() {

                _private.itemsWrapper.css("left" , "0px");
                _private.itemsWrapper.css("width" , "auto");
                _private.buttonPrev.removeClass("visible");
                _private.buttonNext.removeClass("visible");

                _private.setup();
                _private.setProperties();
                _private.clickListener();
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.setProperties();
            _private.clickListener();

            // WINDOW RESIZE
            function doResize() {

                _private.update();
                
            }
            var resizeTimer;
            $(window).resize(function() {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(doResize, 500);
            });
            
        }
             
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mActsSlider = new $.mActsSlider($(".m-acts-slider"));
    });

    
});
