jQuery.extend({

    mBlockEqual: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            blockHeight : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;

            },
            setupBlockHeight:function() {
                
                _private.blockHeight        = 0;

                _private.holder.find(".m-block").each(function(_index, _ell) {

                    var h = $(this).outerHeight();

                    if (_private.blockHeight < h) {
                        _private.blockHeight = h;
                    }

                });
            },
            setBlockHeight:function() {
                _private.holder.find(".m-block").each(function(_index, _ell) {
                   $(_ell).css("height" , _private.blockHeight + "px");
                });
            },

            update:function() {
                

                $(".equal-h-cols").find(".m-block").each(function(_index, _ell) {
                    $(this).removeAttr( "style" );
                });
                _private.setup();
                _private.setupBlockHeight();
                _private.setBlockHeight();
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.setupBlockHeight();
            _private.setBlockHeight();
        }
        // WINDOW RESIZE
        function doResize() {         

            _private.update();
            
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });

        
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mBlockEqual = new $.mBlockEqual($(".equal-h-cols"));
    });

    
});