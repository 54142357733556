jQuery.extend({

    mNavigation: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder          : _this,
            mobileButton    : null,
            navOverlay      : null,
            headerHeight    : null,
            stickyNav       : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.mobileButton       = $(document).find('.m-navigation__mobile-toggle__btn');
                _private.navOverlay         = $(document).find('.m-nav__mobile-overlay');
                _private.headerHeight       = $(document).find(".m-header").outerHeight();
                _private.stickyNav          = $(document).find(".l-nav--sticky");
            },
            clickListener:function() {
                _private.mobileButton.bind('click', function() {
                    _private.holder.toggleClass('open');
                    _private.navOverlay.toggleClass('is-active');
                    $('body').toggleClass('overflow-hidden');
                    $(this).toggleClass('is-active');
                });

            },
            scrollListener:function() {
                $(window).scroll(function(){
                    
                    var top = $(window).scrollTop();

                    if (top > _private.headerHeight) {
                        _private.stickyNav.addClass("is--active");
                    }
                    else {
                        _private.stickyNav.removeClass("is--active");
                    }
                      
                })


            },


            resetNav:function() {
               
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.clickListener();
            _private.scrollListener();

        }
            // WINDOW RESIZE
        function doResize() {

            _private.resetNav();
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
    }
});
$(function()
{
    $(document).ready(function()
    {
        var mNavigation = new $.mNavigation($(".m-nav"));
    });
});
