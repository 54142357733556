jQuery.extend({

    mTestimonial: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            holderItems : null,
            buttonLeft  : null,
            buttonRight : null,
            itemCount   : null,
            visibleItemCount   : null,
            holderWidth : null,
            itemWidth   : null,
            lastVisible : null,
            testimonialHeight : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {

                _private.holder             = holder;
                _private.holderItems        = _private.holder.find(".m-testimonial__items");
                _private.itemsWrapper       = _private.holderItems.find(".m-testimonial__items__wrapper");
                _private.buttonPrev         = _private.holder.find(".m-testimonial__btn-prev");
                _private.buttonNext         = _private.holder.find(".m-testimonial__btn-next");
                _private.itemCount          = _private.itemsWrapper.find(".m-testimonial__item").length;

                _private.holderWidth        = _private.holderItems.outerWidth();
                _private.itemWidth          = _private.holderItems.find(".m-testimonial__item:first-child").outerWidth();

                _private.visibleItemCount   = parseInt(_private.holderWidth / _private.itemWidth);
                _private.lastVisible        = _private.visibleItemCount;
                _private.testimonialHeight        = 0;

            },
            setProperties:function() {
                
                var totalWidth = parseInt( _private.itemCount * _private.itemWidth);
                var visibleWidth = parseInt( _private.visibleItemCount * _private.itemWidth);

                if (_private.holderWidth < totalWidth) {
                    _private.showBtnNext();
                }

                _private.itemsWrapper.find(".m-testimonial__item").each(function(_index, _ell) {
                    $(this).css("width" , "" + _private.itemWidth + "px");
                });

                _private.holderItems.css("width" , "" + visibleWidth + "px");
                _private.itemsWrapper.css("width" , "" + (totalWidth + 2) + "px");  

            },
            setupTestimonialHeight:function() {
                
                 _private.itemsWrapper.find(".m-testimonial__item").each(function(_index, _ell) {
                    var h = $(this).find(".m-block").outerHeight();

                    if (_private.testimonialHeight < h) {
                        _private.testimonialHeight = h;
                    }
                });

                // Set nav top position
                var offsetTop = _private.holderItems.position().top;
                var navTop = offsetTop + parseInt(_private.testimonialHeight / 2) + 15;

                _private.buttonPrev.css("top" , "" + navTop + "px");
                _private.buttonNext.css("top" , "" + navTop + "px");

            },
            setTestimonialHeight:function() {

                 _private.itemsWrapper.find(".m-testimonial__item").each(function(_index, _ell) {
                    $(this).find(".m-block").css("height" , _private.testimonialHeight + "px");
                });
            },
            showBtnPrev:function() {
                _private.buttonPrev.addClass("visible");
            },
            hideBtnPrev:function() {
                _private.buttonPrev.removeClass("visible");
            },
            showBtnNext:function() {
                _private.buttonNext.addClass("visible");
            },
            hideBtnNext:function() {
                _private.buttonNext.removeClass("visible");
            },
            clickListener:function() {
                    _private.buttonPrev.unbind().bind("click", _private.leftClicked);
                    _private.buttonNext.unbind().bind("click", _private.rightClicked);
            },
            leftClicked:function() {
                _private.lastVisible--;

                if (_private.lastVisible == _private.visibleItemCount){
                    _private.hideBtnPrev();

                     _private.itemsWrapper.animate({
                        left: "+=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    });
                }
                else if(_private.lastVisible > _private.visibleItemCount) {
                     _private.itemsWrapper.animate({
                        left: "+=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    });
                }

                if (_private.lastVisible < _private.itemCount) {
                    _private.showBtnNext();
                }
            },
            rightClicked:function() {
                 _private.lastVisible++;

                if (_private.lastVisible < _private.itemCount) {

                    _private.itemsWrapper.animate({
                        left: "-=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    }); 

                }
                else if (_private.lastVisible == _private.itemCount) {
                    _private.hideBtnNext();

                    _private.itemsWrapper.animate({
                        left: "-=" + _private.itemWidth + "px"
                        }, 500, function() {
                            // Animation complete.
                    }); 
                }

                if (_private.lastVisible > _private.visibleItemCount){
                    _private.showBtnPrev();
                }

            },
            update:function() {
                _private.itemsWrapper.css("left" , "0px");
                _private.itemsWrapper.css("width" , "100%");
                _private.holderItems.css("width" , "100%");
                _private.buttonPrev.removeClass("visible");
                _private.buttonNext.removeClass("visible");

                
                if (Modernizr.mq('(max-width: 767px)')) {
                    _private.itemsWrapper.find(".m-testimonial__item").each(function(_index, _ell) {
                        $(this).css("width" , "100%");
                     });
                } else {
                    _private.itemsWrapper.find(".m-testimonial__item").each(function(_index, _ell) {
                        $(this).css("width" , "50%");
                     });
                }
            
                _private.itemsWrapper.find(".m-block").each(function(_index, _ell) {
                    $(this).css("height" , "auto");
                });

                _private.setup();
                _private.setProperties();
                _private.clickListener();
                _private.setupTestimonialHeight();
                _private.setTestimonialHeight();
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.setProperties();
            _private.clickListener();
            _private.setupTestimonialHeight();
            _private.setTestimonialHeight();
            
            // WINDOW RESIZE
            function doResize() {

                _private.update();
                
            }
            var resizeTimer;
            $(window).resize(function() {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(doResize, 500);
            });

        }
        
             
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mTestimonial = new $.mTestimonial($(".m-testimonial"));
    });

    
});