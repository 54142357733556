jQuery.extend({

    mActs: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            holderNav : null,
            holderItems : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.holderNav        = _private.holder.find(".m-acts__nav");
                _private.holderItems        = _private.holder.find(".m-acts__items");

            },

            clickListener:function() {
                _private.holderNav.find("b").each(function(_index, _ell) {

                    $(this).bind('click', function() {
                        var target = $(this).attr("data-target");
                        
                        _private.holderNav.find(".is--active").removeClass("is--active");
                        $(this).addClass("is--active");

                        if (target == "cat-all") {
                            _private.showAll();
                        }
                        else {
                            _private.filterActs(target);
                        }
                    });
                });
            },
            showAll:function() {
                _private.holderItems.find(".m-acts__item").each(function(_index, _ell) {
                    $(this).removeClass("hide");
                });
            },
            filterActs:function(target) {

                _private.holderItems.find(".m-acts__item").each(function(_index, _ell) {
                    if (!$(this).hasClass(target)) {
                        $(this).addClass("hide");
                    }
                    else {
                         $(this).removeClass("hide");
                    }
                });

            },
            update:function() {
                
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.clickListener();
            
        }
        // WINDOW RESIZE
        function doResize() {

            _private.update();
            
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
             
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mActs = new $.mActs($("#m-acts"));
    });

    
});
