jQuery.extend({

    mNews: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            blockHeight : null,
            itemCount : null,
            visibleCount : null,
            moreButton : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = holder;
                _private.itemCount          = holder.find(".m-block--news").length;
                _private.blockHeight        = _private.holder.find(".m-block--news").find(".m-block--news__inner").first().height();
                _private.moreButton         = _private.holder.find("#m-news_show-more");
                _private.moreText           = _private.holder.find("#m-news__more-text");

                _private.visibleCount = 1;

                _private.holder.find(".m-block--news").each(function(_index, _ell) {

                    if (_private.visibleCount <= 3) {

                        $(this).addClass("is--visible");
                        $(this).find(".o-read-more").addClass("is--visible");

                        _private.visibleCount++;
                    }
                    
                });

                _private.moreButton.bind('click', function() {
                    _private.showMore();
                });
            },
            showMore:function() {
                
                console.log("more");
                var count = 1;

                if ((_private.visibleCount + 3) > _private.itemCount) {
                    _private.moreButton.hide();
                    _private.moreText.show();
                }

                while (count <= 3) {
                    
                    var item = _private.holder.find(".m-block--news-" + _private.visibleCount);

                    item.addClass("is--visible");
                    $(item).find(".o-read-more").addClass("is--visible");

                    _private.visibleCount++;
                    count++;
                    
                }

            },

            update:function() {
                
                // _private.setup();
                // _private.setupBlockHeight();
                // _private.setBlockHeight();
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            //_private.setupBlockHeight();
            //_private.setBlockHeight();
        }
        // WINDOW RESIZE
        function doResize() {

            _private.update();
            
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });

        
    }
});
$(function()
{
    $(document).ready(function()
    {
         var mNews = new $.mNews($("#m-news"));
    });
    
});
